<template>
    <div class="project_form">
        <div class="project_form__wrapper">
            <h2>Внесение изменений</h2>
            <a-form-model
                v-if="actionInfo"
                ref="investForm"
                :model="form">
                <div class="form_block">
                    <div class="form_block__header">
                        <h3>Основная информация о проекте</h3>
                    </div>
                    <div class="grid gap-4 xl:gap-6 grid-cols-1 xl:grid-cols-2">
                        <a-form-model-item 
                            v-if="actionInfo.stage" 
                            ref="stage" 
                            label="Стадия проекта" 
                            :rules="{
                                required: false,
                                message: $t('wgr.field_require'),
                                trigger: 'blur',
                            }"
                            prop="stage">
                            <a-select 
                                v-model="form.stage"
                                placeholder="Стадия проекта" 
                                size="large" 
                                class="w-full"
                                :default-active-first-option="false"
                                :not-found-content="null"
                                :loading="stageLoading">
                                <a-select-option 
                                    v-for="stage in stageList.results" 
                                    :key="stage.id" 
                                    :value="stage.id">
                                    {{ stage.string_view }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                        <a-form-model-item  
                            v-if="actionInfo.installation_stage" 
                            ref="installation_stage" 
                            label="Стадия СМР, %" 
                            :rules="{
                                required: true,
                                message: $t('wgr.field_require'),
                                trigger: 'blur',
                            }"
                            prop="installation_stage">
                            <a-input-number
                                v-model="form.installation_stage"
                                class="w-full"
                                :min="0"
                                :max="100"
                                placeholder="Стадия СМР, %"
                                size="large" />
                        </a-form-model-item>
                    </div>
                    <div class="grid gap-4 xl:gap-6 grid-cols-1 xl:grid-cols-2">
                        <a-form-model-item 
                            v-if="actionInfo.project_capacity" 
                            ref="project_capacity" 
                            label="Мощность проекта" 
                            :rules="{
                                required: true,
                                message: $t('wgr.field_require'),
                                trigger: 'blur',
                            }"
                            prop="project_capacity">
                            <a-input-number
                                v-model="form.project_capacity"
                                :step="0.01"
                                class="w-full"
                                placeholder="Укажите мощность проекта"
                                size="large" />
                        </a-form-model-item>
                        <a-form-model-item 
                            v-if="actionInfo.measure_unit" 
                            ref="measure_unit" 
                            label="Единица измерения" 
                            :rules="{
                                required: true,
                                message: $t('wgr.field_require'),
                                trigger: 'blur',
                            }"
                            prop="measure_unit">
                            <a-select 
                                v-model="form.measure_unit"
                                placeholder="Единица измерения" 
                                size="large" 
                                class="w-full"
                                :default-active-first-option="false"
                                :not-found-content="null"
                                :loading="measureUnitLoading">
                                <a-select-option 
                                    v-for="unit in measureUnitList.results" 
                                    :key="unit.id" 
                                    :value="unit.code">
                                    {{ unit.string_view }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </div>
                </div>
                <div class="form_block">
                    <div class="form_block__header">
                        <h3>Стоимость проекта</h3>
                    </div>
                    <div class="grid gap-4 xl:gap-6 grid-cols-1 xl:grid-cols-2">
                        <a-form-model-item 
                            v-if="actionInfo.funds" 
                            ref="funds" 
                            label="Вся стоимость проекта, млн. тенге" 
                            :rules="{
                                required: true,
                                message: $t('wgr.field_require'),
                                trigger: 'blur',
                            }"
                            prop="funds">
                            <a-input-number
                                v-model="form.funds"
                                :step="0.01"
                                class="w-full"
                                placeholder="Вся стоимость проекта"
                                size="large" />
                        </a-form-model-item>
                    </div>
                    <div class="grid gap-4 xl:gap-6 grid-cols-1 xl:grid-cols-2">
                    </div>
                    <div v-if="actionInfo.funding_sources" class="form_block">
                        <div 
                            v-for="(source, index) in form.funding_sources" 
                            :key="source.key"
                            :class="form.funding_sources.length > 1 && 'mb-3'">
                            <div class="equipment_header flex items-center justify-between">
                                <div class="e_label">Источник финансирования</div>
                                <div v-if="form.funding_sources.length > 1">
                                    <a-button type="link" size="small" @click="removeSource(index)">
                                        Удалить
                                    </a-button>
                                </div>
                            </div>
                            <div class="grid gap-4 md:gap-0 xl:gap-6 grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2">
                                <div>
                                    <a-form-model-item 
                                        label="Источник" 
                                        :prop="'funding_sources.' + index + '.funding_source'"
                                        :rules="{
                                            required: true,
                                            message: $t('wgr.field_require'),
                                            trigger: 'blur',
                                        }">
                                        <a-select 
                                            v-model="source.funding_source" 
                                            placeholder="Укажите источник финансирования" 
                                            size="large" 
                                            class="w-full"
                                            :default-active-first-option="false"
                                            :not-found-content="null"
                                            show-search
                                            :filter-option="filterOption"
                                            :loading="sourceLoading">
                                            <a-select-option 
                                                v-for="iSource in fundingSourceList.results" 
                                                :key="iSource.id" 
                                                :value="iSource.id">
                                                {{ iSource.string_view }}
                                            </a-select-option>
                                        </a-select>
                                    </a-form-model-item>
                                    <a-form-model-item 
                                        :prop="'funding_sources.' + index + '.amount'" 
                                        label="Объем финансирования, млн. тенге"
                                        :rules="{
                                            required: true,
                                            message: $t('wgr.field_require'),
                                            trigger: 'blur',
                                        }">
                                        <a-input-number
                                            v-model="source.amount"
                                            :step="0.01"
                                            class="w-full"
                                            placeholder="Укажите объем финансирования"
                                            size="large" />
                                    </a-form-model-item>
                                </div>
                                <a-form-model-item 
                                    :prop="'funding_sources.' + index + '.comment'"  
                                    label="Комментарий"
                                    :rules="{
                                        required: false,
                                        message: $t('wgr.field_require'),
                                        trigger: 'blur',
                                    }">
                                    <a-textarea
                                        v-model="source.comment"
                                        class="equipment_textarea"
                                        placeholder="Добавьте короткий комментарий"
                                        :auto-size="{ minRows: 6, maxRows: 6 }" />
                                </a-form-model-item>
                            </div>
                        </div>
                        <a-button type="primary" ghost block size="large" class="mt-1 mb-5" @click="addSource()">
                            Добавить источник финансирования
                        </a-button>
                    </div>
                </div>
                <div class="form_block">
                    <div class="form_block__header">
                        <h3>Рабочие места</h3>
                    </div>
                    <div class="grid gap-4 xl:gap-6 grid-cols-1 xl:grid-cols-2">
                        <a-form-model-item 
                            v-if="actionInfo.jobs_temporary" 
                            ref="jobs_temporary" 
                            label="Временные" 
                            :rules="{
                                required: false,
                                message: $t('wgr.field_require'),
                                trigger: 'blur',
                            }"
                            prop="jobs_temporary">
                            <a-input-number
                                v-model="form.jobs_temporary"
                                class="w-full"
                                placeholder="Укажите количество временных рабочих мест"
                                size="large" />
                        </a-form-model-item>
                        <a-form-model-item 
                            v-if="actionInfo.jobs_permanent" 
                            ref="jobs_permanent" 
                            label="Постоянные" 
                            :rules="{
                                required: false,
                                message: $t('wgr.field_require'),
                                trigger: 'blur',
                            }"
                            prop="jobs_permanent">
                            <a-input-number
                                v-model="form.jobs_permanent"
                                class="w-full"
                                placeholder="Укажите количество постоянных рабочих мест"
                                size="large" />
                        </a-form-model-item>
                    </div>
                </div>
                <div class="form_block">
                    <a-button type="primary" :loading="loading" block size="large" @click="formSubmit()">
                        Сохранить изменения
                    </a-button>
                </div>
            </a-form-model>
            <a-skeleton v-else active />
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
const formData = {
    installation_stage: '',
    project_capacity: '',
    measure_unit: null,
    funds: '',
    work_experience: '',
    jobs_temporary: '',
    jobs_permanent: '',
    questions: '',
    funding_sources: [
        {
            key: Date.now(),
            funding_source: null,
            amount: null,
            comment: ''
        }
    ]
}

export default {
    props: {
        project: {
            type: Object,
            default: () => {}
        },
        actionInfo: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            countryLoading: false,
            sourceLoading: false,
            stageLoading: false,
            loading: false,
            form: {...formData},
            measureUnitLoading: false,
            measureUnitList: {
                results: []
            },
            fundingSourceList: {
                results: []
            },
            stageList: {
                results: []
            }
        }
    },
    created() {
        this.getMeasureUnit()
        this.getProjectStages()
        this.getFundingSourcesList()
        const formInit = {...this.project}
        if(formInit.funding_sources?.length) {
            formInit.funding_sources = formInit.funding_sources.map(source => {
                return {
                    ...source,
                    key: source.id,
                    funding_source: source.funding_source.id
                }
            })
        }

        if(formInit.measure_unit?.code)
            formInit.measure_unit = formInit.measure_unit.code
        if(formInit.stage?.id)
            formInit.stage = formInit.stage.id
        this.form = formInit
    },
    methods: {
        async getProjectStages() {
            if(!this.stageList.results.length) {
                try {
                    this.stageLoading = true
                    const params = {
                        model: "invest_projects_info.InvestProjectStageModel"
                    }
                    const { data } = await this.$http.get('/app_info/select_list/', {
                        params
                    })
                    if(data?.selectList?.length) {
                        this.stageList.results = data.selectList
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.stageLoading = false
                }
            }
        },
        async getMeasureUnit() {
            if(!this.measureUnitList.results.length) {
                try {
                    this.measureUnitLoading = true
                    const params = {
                        model: "invest_projects_info.InvestProjectMeasureUnitModel"
                    }
                    const { data } = await this.$http.get('/app_info/select_list/', {
                        params
                    })
                    if(data?.selectList?.length) {
                        this.measureUnitList.results = data.selectList
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.measureUnitLoading = false
                }
            }
        },
        async getFundingSourcesList() {
            if(!this.fundingSourceList.results.length) {
                try {
                    this.sourceLoading = true
                    const params = {
                        model: "invest_projects_info.InvestProjectFundingSourceModel"
                    }
                    const { data } = await this.$http.get('/app_info/select_list/', {
                        params
                    })
                    if(data?.selectList?.length) {
                        this.fundingSourceList.results = data.selectList
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.sourceLoading = false
                }
            }
        },
        removeSource(index) {
            this.form.funding_sources.splice(index, 1)
        },
        addSource() {
            this.form.funding_sources.push({
                key: Date.now(),
                funding_source: null,
                amount: null,
                comment: ''
            })
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
        },
        formSubmit() {
            this.$refs['investForm'].validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        const formData = {...this.form}
                        if(formData.has_documentation === 'true') {
                            formData.has_documentation = true
                        } else {
                            formData.has_documentation = false
                        }
                        if(formData.organization?.id) {
                            formData.organization = formData.organization.id
                        }
                        if(formData.category?.id) {
                            formData.category = formData.category.id
                        }
                        if(formData.subcategory?.id) {
                            formData.subcategory = formData.subcategory.id
                        }
                        if(formData.location?.id) {
                            formData.location = formData.location.id
                        }
                        delete formData.author

                        const { data } = await this.$http.put(`/invest_projects_info/${formData.id}/`, formData)
                        if(data) {
                            this.$message.success('Проект успешно обновлен')
                            eventBus.$emit('update_invest_full_project', data)
                            eventBus.$emit('update_price_chart')
                        }
                    } catch(error) {
                        console.log(error)
                        this.$message.error('Ошибка сохранения')
                    } finally{
                        this.loading = false
                    }
                } else
                    return false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.project_form{
    .equipment_header{
        margin-bottom: 10px;
        .e_label{
            font-size: 16px;
            color: #000;
        }
    }
    .equipment_textarea{
        height: 80px!important;
        min-height: 80px!important;
        max-height: 80px!important;
        @media (min-width: 1280px) {
            height: 130px!important;
            min-height: 130px!important;
            max-height: 130px!important;
        }
    }
    &__wrapper{
        border: 1px solid var(--border2);
        border-radius: var(--borderRadius);
        padding: 15px;
        @media (max-width: 767px) {
            background: #fff;
        }
        @media (min-width: 768px) {
            padding: 20px;
        }
        @media (min-width: 1700px) {
            padding: 30px;
        }
        h2{
            font-size: 22px;
            color: #000;
            margin-bottom: 15px;
            margin-top: 0px;
            @media (min-width: 768px) {
                margin-bottom: 30px;
                font-size: 32px;
            }
        }
    }
    .form_block{
        &:not(:last-child){
            margin-bottom: 20px;
            border-bottom: 1px solid var(--border2);
            padding-bottom: 10px;
        }
        &__header{
            margin-bottom: 10px;
            h3{
                font-size: 20px;
                margin: 0px;
                font-weight: 400;
            }
        }
    }
}
</style>